import { useEffect, useState } from 'react';
import './user-message.css'
function UserMessage ({text}) {
    const [currentTime, setCurrentTime] = useState('');
    const updateCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
  
        setCurrentTime(
          `${formattedHours}:${formattedMinutes} ${ampm}`
        );
      };
    useEffect(() => {
        updateCurrentTime()
    }, [text]);
    return (
        <div className="user-message-container" style={{position : "relative"}}>
            <span className='time-user' style={{position : "absolute" , color : "white" , fontFamily : "Gilroy-Light, sans-serif"}}>{currentTime}</span>
            <div className='chat-text-user'>{text}</div>
        </div>
    )
}

export default UserMessage ;